import { createApp } from 'vue';
import App from './App.vue';

/* 插件 */
import router from './router';
import { pinia } from '@/store';
import SLoading from '@sms/components/plugins/SLoading';
import authDirective from '@/directives/auth';
import useGetBuildInfo from '@sms/common/composables/useGetBuildInfo';

/* 樣式 */
import '@/styles/main.css';
import 'floating-vue/dist/style.css';

createApp(App).use(authDirective).use(pinia).use(router).use(SLoading).mount('#app');

const { VITE_BUILD_VERSION, VITE_BUILD_DATE } = useGetBuildInfo();

console.log(`${VITE_BUILD_DATE}\n${VITE_BUILD_VERSION}\n${import.meta.env.MODE}`);
