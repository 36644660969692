export default function useGetBuildInfo() {
  function getMetaData(metaName: string) {
    return document.querySelector(`meta[name=${metaName}]`)?.getAttribute('content');
  }

  return {
    VITE_BUILD_DATE: getMetaData('build-date'),
    VITE_BUILD_VERSION: getMetaData('app-version')
  };
}
